const appearancesContent = [
    {
        id: 0,
        title: "PEN America Presents, Breaking the News",
        date: "September 20, 2020",
        venue: "Founders Hall, Brooklyn",
        link: "https://www.youtube.com/watch?app=desktop&v=0c-jD_f1t9s",
        blurb: "What is the state of press freedom in the United States? How can news consumers know what’s real news and what’s fake? How is the press covering (or not covering) under-represented communities? And heading into what promises to be a contentious campaign season, how can journalists do their jobs under increased threat? CNN’s Jim Acosta (The Enemy of the People: A Dangerous Time to Tell the Truth in America), PEN America’s Suzanne Nossel, and MSNBC’s Joy Reid (The Man Who Sold America: Trump and the Unraveling of the American Story) weigh in on journalism in the time of Trump in a conversation moderated by WNYC’s Jami Floyd.",
    },
    {
        id: 1,
        title: "How to Citizen with Baratunde Thurston in Conversation with Jami Floyd",
        date: "November 5, 2020",
        venue: "The Greene Space, NYC",
        link: "https://www.thegreenespace.org/watch/podcast-mixtape-baratunde-thurstons-how-to-citizen-2/",
        blurb: "WNYC’s Jami Floyd (Senior Editor, Race and Justice) joins Baratunde to discuss his wide-ranging career and what he’s learned from sitting down with activists, revolution aries and experts about the responsibilities we all have to our country.",
    },
    {
        id: 2,
        title: "Emcee (with Brian Lehrer), MLK and the Fierce Urgency of Now",
        date: "January 18, 2021",
        venue: "Apollo Theater, Harlem",
        link: "https://www.thegreenespace.org/event/wnyc-and-apollo-theater-present-mlk-and-the-fierce-urgency-of-now/",
        blurb: "On this Martin Luther King Jr. Day, WNYC’s Brian Lehrer and Jami Floyd host luminaries and leaders in the fields of health, politics, journalism and more to address the implications of the 2020 U.S. Presidential Election through the prisms of civil rights and modern day social justice movements at the Apollo Theater including House Majority Whip James Clyburn, journalist Nikole Hanna-Jones, New York Attorney General Letitia James, Dr. Jeff Gardere, Dr. Reverend William Barber Jr. and other special guests, who lend a specific focus on the Black community’s historic and adversarial relationship with law enforcement and western medicine institutions.",
    },
    {
        id: 3,
        title: "Brennan Center Punitive Excess Symposium",
        date: "April 13, 2021",
        venue: "The Greene Space, NYC",
        link: "",
        blurb: "Panelist as part of Brennan Center Symposium at WNYC examining the punitive excess that has come to define America’s criminal legal system. Moderated by Jeremy Travis of the Brennan Center and co director with Bruce Western of the Square One Project and of the Columbia Justice Lab.",
    },
    {
        id: 4,
        title: "In Conversation with Nikole Hannah-Jones",
        date: "January 10, 2022",
        venue: "",
        link: "https://www.youtube.com/watch?v=ZbKW4rx2QLk&list=PL3YLFQrIo3QFgeKHWdz92H0dMcWyHGEWO&index=3",
        blurb: "",
    },
    {
        id: 5,
        title: "Moderator & Host, Deep River: Black Culture in Classical Music",
        date: "February 3, 2022",
        venue: "The Greene Space, NYC",
        link: "https://www.thegreenespace.org/event/deep-river-black-currents-in-classical-music-virtual/",
        blurb: "Jami Floyd moderates a conversation about the rich tradition of Black composers, conductors, and musicians in classical music with Dr. Howard Watkins, renowned pianist and Assistant Conductor at the Metropolitan Opera, explores this lineage by curating a recital delving into the rich repertoire of Black American composers, featuring internationally acclaimed soprano Karen Slack and baritone Kenneth Overton. WNYC’s Jami Floyd hosts this illuminating musical performance followed by an insightful panel discussion about the over 100-year tradition of Blacks as creators, conductors, and patrons of classical music.",
    },
    {
        id: 6,
        title: "Science Leadership for the Future",
        date: "April 10, 2022",
        venue: "Binghamton University, Binghamton, NY",
        link: "",
        blurb: "Jami moderates a panel with young leaders in scientific research and scholarship: Dr. Tracy Brooks, associate professor, Pharmaceutical Sciences, Dr. Elizabeth DiGangi, associate professor, Anthropology, and Dr. Nicholas Gaspelin, assistant professor, Psychology."
    },
    {
        id: 7,
        title: "Co-host and moderator, Harriet Tubman Center for Freedom and Equality",
        date: "October 14, 2022",
        venue: "SUNY Global Center, NYC",
        link: "https://www.binghamton.edu/centers/harriet-tubman/events.html",
        blurb: "A conversation with Sunny Hostin to showcase and celebrate the work of Dr. Anne C. Bailey and the Harriet Tubman Center at Binghamton University. The first of three salons with HTC leadership and supporters."
    },
    {
        id: 8,
        title: "In Conversation with actor Tracy Thorne and litigator Shelley Ann Quilty-Lake",
        date: "November 20, 2022",
        venue: "Irish Repertory Theatre, Off-Broadway",
        link: "",
        blurb: "Jami moderates a conversation at the Irish Rep about Tracy Thorne the solo play Jack Was Kind, which Thorne wrote and performs and Floyd helped produce. Directed by Nicholas Kotz.",
    },
    {
        id: 9,
        title: "In Conversation with Denzel & Pauletta Washington",
        date: "Nov 21, 2022",
        venue: "Washington School of the Arts, Mount Vernon",
        link: "",
        blurb: "ami moderates a conversation with Denzel Washington when he returns to the his high school in Mount Vernon after it’s renamed in his honor. With him, his wife of forty years, Pauletta. They speak about the arts, education, their children and long partnership to an auditorium of students and faculty and staff. Hosted by Principal Evelyn Collins."
    },
    {
        id: 10,
        title: "In Conversation with Darren Walker, Person, Place Thing",
        date: "Dec 13, 2022",
        venue: "The Ford Foundation, NYC",
        link: "",
        blurb: "A conversation with Darren Walker for PPP based on the idea that people are particularly engaging when they speak, not directly about themselves, but about one person, one place, and one thing with particular meaning to them. Musical guest: Rashad Brown",
    },
    {
        id: 11,
        title: "Speech, Making and Exoneree",
        date: "February 10, 2023",
        venue: "Georgetown Law Center, Georgetown",
        link: "",
        blurb: "Jami Speaks with the Prisons and Justice Initiative led by Marc Howard and Marty Tankleff about the role of journalism in the pursuit of innocence cases, as well as some of the significant cases she’s covered.",
    },
    {
        id: 12,
        title: "In Conversation with Myrlie Evers-Williams and Jerry Mitchell",
        date: "March 20, 2023",
        venue: "Pepperdine University, Los Angeles",
        link: "",
        blurb: "At the start of her career, in 1998, Jami reported on the Mississippi retrial of Byron de la Beckwith for the murder of civil rights leader Medgar Evers. At that time, she met and interviewed Medgar Evers’s widow, Myrlie Evers-Williams. Jami carried the memory of the conversation with her as the most meaningful of her career. In 2023, on the occasion of Myrlie Evers Williams’s 90th birthday, Jami had the privilege of interviewing Ms. Evers-Williams before an audience of hundreds.",
    },
    {
        id: 13,
        title: "Nabil Ayers, Book Talk, My Life in the Sunshine",
        date: "March 24, 2023",
        venue: "New York Society Library",
        link: "",
        blurb: "A conversation with music producer Nabil Ayers for the publication of his memoir, My Life in the Sunshine.",
    },
    {
        id: 14,
        title: " Moderator, The White Institute Conference, Living in the Liminal Space: an AAPI Experience",
        date: "April 2, 2023",
        venue: "Fordham University, NYC",
        link: "",
        blurb: "Jami moderates a conversation with Karen Chuck, Arun Venugopal and Milan Patel about Asian Identity, and the paradox of privilege and negation. The panel examines the clinical implications of working with AAPI clients contextualized by the social and historical forces that shape their lived experience.",
    },
    {
        id: 15,
        title: "In Conversation with Angela Davis",
        date: "June 16, 2023",
        venue: "Seward House Museum, Auburn",
        link: "",
        blurb: "Angela Davis, an iconic activist, feminist, and acclaimed author, sits in conversation with Jami Floyd to commemorate Juneteenth, hosted by the Seward House Museum.",
    },
    {
        id: 16,
        title: "Fireside Chat with Sherrilyn Ifill",
        date: "July 2, 2023",
        venue: "Baltimore Museum of Art, Baltimore",
        link: "",
        blurb: "In celebration of Justice Thurgood Marshall’s birthday, Jami Floyd and former NAACP Director-Counsel Sherrilyn Ifill participated in a fireside chat at the Baltimore Art Museum. They were in conversation with Vic Carter, WJZ anchor.",
    },
    {
        id: 17,
        title: "Book Talk, Fatimah Gilliam’s, Race Rules",
        date: "March 14, 2024",
        venue: "Double Knot Gallery, Tribeca",
        link: "",
        blurb: "A conversation with attorney and consultant Fatimah Gilliam for the publication of her book, Race Rules: What Your Black Friend Won’t Tell You.",
    },
    {
        id: 18,
        title: "Graduation Keynote Speaker",
        date: "June 1, 2024",
        venue: "St. Thomas Choir School, New York City",
        link: "",
        blurb: "",
    },
];

export default appearancesContent;