import React from 'react';

const Button = (props) => {
    const orangeButtonClasses = 'border border-orange-400 py-4 px-20 hover:bg-orange-400 text-orange-400 hover:text-white transition-colors duration-400 cursor-pointer';
    const grayButtonClasses = 'border border-gray-700 py-4 px-20 hover:bg-gray-700 text-gray-700 hover:text-white transition-colors duration-400 cursor-pointer';

    // set a var called buttonClasses equal to orangeButtonClasses if props.color is ornage, and grauButtonClasses if props.color is gray

    const buttonClasses = props.color === 'orange' ? orangeButtonClasses : props.color === 'gray' ? grayButtonClasses: '';
    return (
        <a href={props.href} class={buttonClasses}>
            <span class="font-serif text-lg font-bold">{props.text}</span>
        </a>
    );
};

export default Button;
