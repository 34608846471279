import React, { useState } from 'react';

import { useMasterContext } from '../../contexts/MasterContext';

import parseBlogDate from '../../functions/blog/parseBlogDate';

const BlogPosts = () => {

    const { blogPosts, setShowBlogPosts } = useMasterContext();

    const [isAPostHovered, setIsAPostHovered] = useState(false);


    return (
        <div class="fixed p-12 top-0 left-0 z-50 w-screen h-screen overflow-y-scroll flex items-start justify-center bg-white font-serif animate-fade animate-duration-200">
            <div class="max-w-7xl mx-auto w-full flex flex-col items-center justify-end">
                <div class="flex flex-row items-center justify-end w-full">
                    <div onClick={() => setShowBlogPosts(false)} class="cursor-pointer text-gray-700 hover:text-gray-600">
                        <svg fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" class="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>

                {blogPosts && blogPosts.length > 0 && (
                    <div 
                        onMouseEnter={() => setIsAPostHovered(true)}
                        onMouseLeave={() => setIsAPostHovered(false)}
                        class="flex flex-col items-center justify-center space-y-12 py-16"
                    >
                        {blogPosts.map((blogPost, index) => (
                            <ul key={index} class={`inline text-center space-x-2 text-4xl text-bold sm:text-5xl ${isAPostHovered ? 'text-gray-500' : 'text-gray-900'}  hover:text-gray-900 transition-colors duration-400`}>
                                <a href={blogPost.link} target="_blank" rel="noreferrer" class="text-center">{blogPost.title}</a>
                                    <svg fill="none" viewBox="0 0 24 24" strokeWidth={0.5} stroke="currentColor" className="inline w-10 h-10">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
                                    </svg>
                                <a href={blogPost.link} target="_blank" rel="noreferrer" class="text-center">{parseBlogDate(blogPost.pubDate)}</a>
                            </ul>
                        ))}
                    </div>
                )}

            </div>
        </div>
    );
};

export default BlogPosts;
