import React, { useState, useEffect } from 'react';

import appearancesContent from './AppearancesContent';

const AppearancesGrid = () => {

  const [hoveredAppearance, setHoveredAppearance] = useState(null);

  return (
    <div class="max-w-7xl p-4 w-full">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-x-8 md:gap-y-16 gap-y-2 w-full">
        {appearancesContent && appearancesContent.length > 0 && appearancesContent.slice().reverse().map((appearance, index) => {
            return (
              <div
                key={index}
                onMouseEnter={() => setHoveredAppearance(appearance)}
                onMouseLeave={() => setHoveredAppearance(null)}
              >
                <div class="flex flex-col items-start justify-center w-full space-y-2 cursor-pointer">
                  <p class="">{appearance.date}</p>
                  <span class="inline-flex flex-wrap flex-row items-center justify-start w-full">
                    <span class="uppercase font-serif text-lg">{appearance.title}</span>
                    {/* <span class="hidden font-mono text-xs sm:inline">&nbsp;{appearance.date}</span> */}
                  </span>
                  {/* <a class="transition-colors duration-200 font-serif text-lg cursor-pointer">
                    <span class="inline-flex flex-wrap items-center">
                      {appearance.blurb}
                    </span>
                  </a> */}
                    
                    {hoveredAppearance && hoveredAppearance === appearance && appearance.link !== "" ? (
                      <div class="flex flex-row items-center justify-start">
                          <a
                              href={appearance.link}
                              target="_blank"
                              class={`font-serif  hover:text-orange-400 animate-fade transition-colors duration-400 cursor-pointer`}
                          >
                              <span class="inline-flex flex-wrap items-center justify-center space-x-2">
                                  Visit<span class="hidden sm:inline">&nbsp;Appearance</span>
                                  <svg fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={"w-4 h-4 transition-opacity duration-400"}>
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                  </svg>
                              </span>
                          </a>
                      </div>

                  ) : (
                      <div class="flex flex-row items-center justify-start space-x-2">
                          <a
                              class="font-serif px-2 border border-transparent"
                          >
                              <span class="inline-flex flex-wrap items-center">
                                  
                              </span>
                          </a>

                          <a
                              class="font-serif px-2 border border-transparent"
                          >
                              <span class="inline-flex flex-wrap items-center">
                                  
                              </span>
                          </a>
                      </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default AppearancesGrid;
