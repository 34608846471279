const retrieveBlogPosts = async () => {
    const username = "jamifloyd";
    try {
        const response = await fetch(`https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@${username}&count=10`, {
            method: "GET"
        });
        const data = await response.json();
        return data.items;

    } catch (error) {
        console.error('Error fetching publications:', error);
        return [];
    }
};

export default retrieveBlogPosts;
