import React, { useState, useEffect } from 'react';
import { useMasterContext } from '../../contexts/MasterContext';

import timelineContent from './TimelineContent.js';


const Timeline = () => {

  const [timeline, setTimeline] = useState(timelineContent);

  return (
    <ol className="relative col-span-8 border-l border-gray-200">
      {timeline && timeline.length > 0 && timeline.map((episode, index) => (
        <li key={index} className="mb-10 ml-4">
          <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white"></div>
          <time class="mb-1 text-sm font-normal leading-none text-stone-400">{episode.date}</time>

          <h3 class="text-lg font-semibold text-gray-700">{episode.title}</h3>
          <p class="mb-4 text-base font-normal text-gray-500">{episode.description}</p>
          {episode.quote !== "" && (
            <blockquote class="mb-4 italic font-normal text-gray-500">"{episode.quote}"</blockquote>
          )}
          {episode.link !== "" && (
            <a href={episode.link} target="_blank" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-700 hover:bg-gray-700 hover:text-white transition-colors duration-400">
              Learn more
              <svg class="w-3 h-3 ml-2" fill="currentColor" viewBox="0 0 20 20">
                <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path>
              </svg>
            </a>
          )}
          {/* {episode.photo !== "" && (
            <a href={episode.photo} target="_blank" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-700 hover:bg-gray-700 hover:text-white transition-colors duration-400">
              View
              <svg class="w-3 h-3 ml-2" fill="currentColor" viewBox="0 0 20 20">
                <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path>
              </svg>
            </a>
          )} */}
        </li>
      ))}
    </ol>
  );
};

export default Timeline;
