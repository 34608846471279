import React, { useEffect, useRef } from 'react';

const H2 = (props) => {

    const textareaRef = useRef(null);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'fit';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, []);

    return (
        <div class="col space-y-8 items-center justify-center w-full ">
            <h1 class="font-serif text-4xl sm:text-6xl text-gray-700 text-center">{props.text}</h1>
            <div class="h-[2px] w-1/4 max-w-[48px] bg-orange-400 mx-auto rounded-full"></div>
        </div>
    );
};

export default H2;