import React, { useRef } from 'react';

import { useMasterContext } from '../../contexts/MasterContext';

import parseHtmlForTease from '../../functions/blog/parseHtmlForTease';

import H3 from '../UI/H3';

const RecentPosts = () => {

    const { blogPosts, handleBlogPostClick } = useMasterContext();

    const posts = blogPosts ? blogPosts.slice(0, 3) : null; // <-- The first three posts for display

    const handleCategoryClick = ( category ) => {
        const formattedCategory = category.split(' ').join('-');
        window.open(`https://medium.com/search?q=${formattedCategory}`)
    };

    return (
        <div className="border-t">
            <div class="max-w-7xl mx-auto p-4 animate-fade">
                <div class="flex flex-col items-center justify-center space-y-8 py-24">
                    <a href='/blog' class="cursor-pointer">
                        <H3 text="Recent Posts" />
                    </a>
                    <div class="grid grid-cols-1 md:grid-cols-3 gap-y-16">
                        {posts && posts.map((post, index) => {
                            const { title, categories, description } = post;

                            const slug = title.split(' ').join('-');

                            const border = index !== posts.length - 1 ? 'md:border-r' : '';
                            const paddingRight = index !== posts.length - 1 ? 'pr-0 md:pr-4' : '';
                            const paddingLeft = index !== 0 ? 'pr-0 md:pl-4' : '';
                            return (
                                <div class={`${border} ${paddingRight} ${paddingLeft}`}>
                                    <div class="flex flex-col items-start justify-between h-full space-y-2">
                                        <a onClick={() => handleBlogPostClick(post)} class="text-2xl underline underline-offset-8 decoration-1 decoration-gray-200 hover:decoration-gray-400 font-serif hover:text-gray-700 transition-colors duration-400 cursor-pointer">{title}</a>
                                        <p class="text-md font-serif text-gray-700">{parseHtmlForTease(description)}</p>
                                        <div class="w-full grid grid-cols-2 gap-x-0 md:gap-x-2">
                                            {categories && categories.map((category) => {
                                                return (
                                                    <p 
                                                        onClick={() => handleCategoryClick(category)}
                                                        class="text-md font-serif text-gray-700 cursor-pointer">
                                                        #{category}
                                                    </p>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecentPosts;