import React, { useState, useEffect } from 'react';

import { useMasterContext } from '../contexts/MasterContext';

import AppearancesGrid from '../components/Appearances/AppearancesGrid';
import AppearancesLoading from '../components/Appearances/AppearancesLoading';
import BlogPosts from '../components/Home/BlogPosts';
import H2 from '../components/UI/H2';
import H3 from '../components/UI/H3';

const Appearances = () => {

    const { showBlogPosts } = useMasterContext();

    const [appearancesLoading, setAppearancesLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setAppearancesLoading(false);
        }, 2000);
    }, []);

    return (
        <div class="animate-fade">
            {showBlogPosts && <BlogPosts />}
            <div class="max-w-7xl px-4 d:px-0 mx-auto space-y-12 font-serif pb-24">
                <div class="max-w-5xl mx-auto flex flex-col items-center justify-between mt-12 space-y-12">
                    <H3 text="Speaking Engagements" />
                    <H2 text="See what Jami's been up to" />
                </div>
                {appearancesLoading ? (
                    <AppearancesLoading />
                ) : (
                    <AppearancesGrid />
                )}
            </div>
        </div>
    );
};

export default Appearances;