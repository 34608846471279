import React, { useState, useEffect } from 'react';

import { useMasterContext } from '../../contexts/MasterContext';

import projectsContent from './ProjectsContent.js'

const ProjectGrid = () => {

  const { handleProjectClick } = useMasterContext();

  const [projects, setProjects] = useState(projectsContent);
  const [hoveredProject, setHoveredProject] = useState(null);


  return (
    <div class="max-w-7xl p-4 w-full">
      <div class="grid grid-cols-2 md:grid-cols-3 gap-x-8 gap-y-16 w-full">
        {projects.length > 0 && projects.map((project, index) => {
            return (
              <div
                key={index}
                onMouseEnter={() => setHoveredProject(project)}
                onMouseLeave={() => setHoveredProject(null)}
              >
                <div class="flex flex-col items-start justify-center w-full space-y-2 cursor-pointer">
                  <div class="flex flex-row items-start justify-start w-full space-x-4">
                    <p class="uppercase font-serif text-lg">{project.title}</p>
                  </div>
                  <a class="transition-colors duration-200 font-serif text-lg cursor-pointer">
                    <span class="inline-flex flex-wrap items-center">
                      {project.blurb}
                    </span>
                  </a>
                    {hoveredProject && hoveredProject === project ? (
                      <div class="flex flex-row items-center justify-start">
                          <a
                              onClick={() => handleProjectClick(project)}  
                              class={`font-serif  hover:text-orange-400 animate-fade transition-colors duration-400 cursor-pointer`}
                          >
                              <span class="inline-flex flex-wrap items-center justify-center space-x-2">
                                  View<span class="hidden sm:inline">&nbsp;Project</span>
                                  <svg fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={"w-4 h-4 transition-opacity duration-400"}>
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                  </svg>
                              </span>
                          </a>
                      </div>

                  ) : (
                      <div class="flex flex-row items-center justify-start space-x-2">
                          <a
                              class="font-serif px-2 border border-transparent"
                          >
                              <span class="inline-flex flex-wrap items-center">
                                  
                              </span>
                          </a>

                          <a
                              class="font-serif px-2 border border-transparent"
                          >
                              <span class="inline-flex flex-wrap items-center">
                                  
                              </span>
                          </a>
                      </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ProjectGrid;
