import React from 'react';

const H1 = (props) => {
    return (
        <div class="col space-y-6 items-center justify-center">
            <h1 class="font-serif text-6xl sm:text-8xl text-gray-700 text-center">{props.text}</h1>
            <div class="h-[2px] w-1/4 max-w-[48px] bg-orange-400 mx-auto rounded-full"></div>
        </div>
    );
};

export default H1;