import React from 'react';

import H4 from '../UI/H4';

const Testemonials = () => {

    const testemonials = [
        {
            comment: 'Jami Floyd is a phenomenal media personality. Her ability to command a space and assess the key points of a debate and then engage both the presenters and the audience in a meaningful discussion is without comparison. She is a gifted and thoughtful speaker with breadth of knowledge on national and international issues.',
            author: 'Delores Jones-Brown',
            title: 'Professor, John Jay College of Criminal Justice City University of New York'
        },
        {
            comment: 'We are truly indebted to Jami Floyd for helping us to shape a new programming series at Federal Hall and masterfully guiding thoughtful conversations about often contentious issues of the day. Her breadth of knowledge, professional preparation and historical acumen gave depth and dimension to the series and a distinctive place in the world of virtual programming.',
            author: 'Ellyn M Toscano',
            title: 'Senior Director for Programming Partnerships & Community Engagement NYU, Brooklyn',
        },
        {
            comment: 'I am full of admiration for Jami Floyd and deeply grateful for her moderation of the \'Brooklyn Resists\' series. She brought insight, sensitivity, and profound generosity to her role leading these conversations. Jami is the definition of professionalism and integrity. The world is a better place because of her contributions.',
            author: 'Marcia Ely',
            title: 'Director of Programs Center for Brooklyn History Brooklyn Public Library',
        },
    ];

    return (
        <div class="border-t py-24">
            <div class="max-w-7xl px-4 d:px-0 mx-auto font-serif">
                <div class="grid grid-cols-1 space-y-8 lg:space-y-0 lg:space-x-8 items-center justify-items-stretch">
                    <div class="flex flex-col items-center justify-start h-full col-span-5 space-y-16 max-w-5xl mx-auto">
                        <H4 text="Let's work together" />
                        <p class="max-w-xl text-gray-700 text-center text-lg">Jami frequently hosts panels, roundtables, and other discussions about topics in race, media, and the American conversation. See what others are saying about Jami.</p>
                        <div class="w-full grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 gap-y-16 gap-x-16">
                            {testemonials.map((testemonial, index) => {
                                const border = index !== 0 ? 'md:border-l' : '';
                                const paddingRight = index !== testemonials.length - 1 ? 'pr-0 md:pr-4' : '';
                                const paddingLeft = index !== 0 ? 'pr-0 md:pl-4' : '';
                                
                                return (
                                    <div
                                        key={index}
                                        class={`${border} ${paddingRight} ${paddingLeft} w-full md:w-auto`}
                                    >
                                        <div class="flex flex-col items-start justify-start h-full space-y-8">
                                            <p class="text-gray-700 text-lg">" {testemonial.comment} "</p>
                                            <p class="text-gray-700 text-md">{testemonial.author} | <span class="italic">{testemonial.title}</span></p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testemonials;
