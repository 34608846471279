import React, { useState, useEffect } from 'react';

import { useMasterContext } from '../contexts/MasterContext';

import Markdown from '../components/BlogPost/Markdown';

const BlogPost = () => {

    const slug = window.location.pathname.split('/')[2];
    const title = slug.split('-').join(' ');

    const { blogPosts, selectedBlogPost } = useMasterContext();

    const [post, setPost] = useState(null);

    useEffect(() => {
        const relevantPost = blogPosts ? blogPosts.find((post) => post.title === title) : null;
        setPost(relevantPost);
    }, []);

    return (
        <div class="animate-fade">
            {selectedBlogPost && (
                <div className="flex flex-col justify-center px-4 font-serif">
                    <div class="self-center max-w-2xl my-10 sm:my-20">
                        <h1 className="text-left text-primary-950 text-8xl text-gray-700">
                            {selectedBlogPost.title}
                        </h1>
                        {selectedBlogPost.categories && selectedBlogPost.categories.length > 0 && (
                            <h2 className="text-primary-800 text-4xl font-semibold mt-2 leading-tight text-gray-700">
                                Subject: {selectedBlogPost.categories && selectedBlogPost.categories.map((category) => '#' + category).join(', ')}
                            </h2>
                        )}
                        

                        <div class="flex w-full border-[0.5px] my-6 sm:my-8" />

                        <div className="flex flex-row items-center justify-center w-full">
                            <Markdown post={selectedBlogPost} />
                        </div>
                    </div>
                </div>

            )}
        </div>
    );
};

export default BlogPost;