const parseHtmlForTease = ( htmlString ) => {
    const div = document.createElement('div');
    div.innerHTML = htmlString;
    const pTags = div.getElementsByTagName('p');
    
    let fullText = '';
    for (let i = 0; i < pTags.length; i++) {
        fullText += pTags[i].textContent;
        fullText += ' ';
    };

    // We want to slice around 300 characters, but not in the middle of a word
    // So we'll slice at the first space after 300 characters
    const tease = fullText.slice(0, 300).split(' ').slice(0, -1).join(' ') + '...';

    return tease;
};

export default parseHtmlForTease;