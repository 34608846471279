import React, { useState, useEffect } from 'react';

import { useMasterContext } from '../contexts/MasterContext';

import parseHtmlForTease from '../functions/blog/parseHtmlForTease';

import BlogPosts from '../components/Home/BlogPosts';
import H2 from '../components/UI/H2';
import H3 from '../components/UI/H3';

const Blog = () => {

    const { content, setContent, blogPosts, showBlogPosts, handleBlogPostClick } = useMasterContext();

    const [subtitle, setSubtitle] = useState('');

    const handleSubtitleChange = ( event ) => {
        const text = event.target.value;
        setSubtitle(text);

        const blog = content.pages.filter((item) => item.title === 'Blog')[0];

        const newBlog = {
            ...blog,
            subtitle: text
        };

        setContent({
            ...content,
            pages: [
                ...content.pages.filter((item) => item.title !== 'Blog'),
                newBlog
            ]
        });
    };

    useEffect(() => {
        if (content) {
            const blog = content.pages.filter((item) => item.title === 'Blog')[0];
            setSubtitle(blog.subtitle);
        }
    }, [content]);

    const handleCategoryClick = ( category ) => {
        const formattedCategory = category.split(' ').join('-');
        window.open(`https://medium.com/search?q=${formattedCategory}`)
    };

    return (
        <div class="animate-fade">
            {showBlogPosts && <BlogPosts />}
            <div class="space-y-12 ">
                <div class="max-w-7xl px-4 d:px-0 mx-auto space-y-12 font-serif pb-24">
                    <div class="flex flex-col items-center justify-center mt-12 space-y-12">
                        <H3 text="Blog" />
                        <H2 text="A glipmse into Jami's thoughts" />
                        <div class="grid grid-cols-1 md:grid-cols-3 gap-y-16 gap-x-4">
                            {blogPosts && blogPosts.map((post, index) => {
                                const { title, categories, description } = post;

                                const slug = title.split(' ').join('-').toLowerCase().replace(/[^\w-]+/g,'');

                                const border = index % 3 !== 0 ? 'md:border-l' : '';
                                const paddingRight = index % 3 !== 0 ? 'pr-0 md:pr-4' : '';
                                const paddingLeft = index % 3 !== 0 ? 'pr-0 md:pl-4' : '';

                                return (
                                    <div class={`${border} ${paddingRight} ${paddingLeft}`}>
                                        <div class="flex flex-col items-start justify-between h-full space-y-2">
                                            <a onClick={() => handleBlogPostClick(post)} class="text-2xl underline underline-offset-8 decoration-1 decoration-gray-200 hover:decoration-gray-400 font-serif hover:text-gray-700 transition-colors duration-400 cursor-pointer">{title}</a>
                                            <p class="text-md font-serif text-gray-700">{parseHtmlForTease(description)}</p>
                                            <div class="w-full grid grid-cols-2 gap-x-0 md:gap-x-2">
                                                {categories && categories.map((category) => {
                                                    return (
                                                        <p 
                                                            onClick={() => handleCategoryClick(category)}
                                                            class="text-md font-serif text-gray-700 cursor-pointer">
                                                            #{category}
                                                        </p>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Blog;