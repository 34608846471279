import React, { useState, useEffect, useRef } from 'react';

import { useMasterContext } from '../contexts/MasterContext';

import BlogPosts from '../components/Home/BlogPosts';

import H2 from '../components/UI/H2';
import H3 from '../components/UI/H3';

const Book = () => {

    const { showBlogPosts } = useMasterContext();

    return (
        <div class="animate-fade">
            {showBlogPosts && <BlogPosts />}
            <div class="space-y-12">
                <div class="max-w-7xl px-4 d:px-0 mx-auto space-y-12 font-serif pb-24">
                    <div class="max-w-5xl mx-auto flex flex-col items-center justify-center mt-12 space-y-12">
                        <H3 text="Dream Interrupted" />
                        <H2 text="Jami signs book deal with Little Brown & Co." />
                        <p className="text-gray-700 text-center text-lg">On April 4, 2022, Jami Floyd announced a book deal with Little Brown and Company. The book, Dream Interrupted: Searching for Thurgood Marshall and the Struggle to Save the Soul of a Nation is to explore the life and legacy of Supreme Court Justice and advocate Thurgood Marshall. In the words of Jami's agent, Laura Dail, Pronoy Sarkar preempted North American rights from Laura Dail at Laura Dail Literary Agency. The book, Dail said, explores how the Supreme Court’s first Black justice “inspired the author’s own life, as well as generations of Americans who continue to grapple with, embody, or complicate, his legacy, while illuminating the struggle for justice that continues to this day.” The book is set to be released in 2025.</p>                      
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Book;