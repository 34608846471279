import React from 'react';

import Button from '../components/UI/Button';

const Error = () => {
    return (
        <div class="space-y-12 pb-36">
            <div class="max-w-7xl px-4 d:px-0 mx-auto font-serif">
                <div class="flex flex-col items-center justify-between space-y-8">
                    <h1 class="text-[200px] font-bold text-gray-700">404</h1>
                    <h1 class="max-w-xl text-lg text-center font-bold text-gray-700">We couldn't find what you're looking for. This page is either unavailable, or an error was raised in your attempt to access it. Please return home and try again.</h1>
                    <Button href="/" text="Return home" color="gray" />
                </div>
            </div>

        </div>
        
    );
};

export default Error;