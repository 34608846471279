import React, { useState } from 'react';
import H1 from '../UI/H1';

const Hero = (props) => {

    const [bounce, setBounce] = useState(false);

    return (
        <div class="max-w-7xl px-4 d:px-0 mx-auto font-serif">
            <div class="py-12 grid grid-cols-1 lg:grid-cols-12 space-y-8 lg:space-y-0 lg:space-x-8 items-center justify-items-stretch">
            <figure class="col-span-7">
                <img
                    src={require('../../assets/Home/jami_2.jpg')}
                    alt="Jami Floyd"
                    class="object-fill w-full h-full"
                />
            </figure>
            <div 
                onMouseEnter={() => setBounce(true)}
                onMouseLeave={() => setBounce(false)}
                class="flex flex-col items-center justify-center h-full col-span-5 space-y-16">
                <H1 text="Jami Floyd" />
                <svg onClick={props.handleScrollToAbout} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" class={`text-gray-700 w-6 h-6 cursor-pointer ${bounce ? 'animate-bounce animate-infinite' : ''}}`}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                </svg>
            </div>
            </div>
        </div>
    );
};

export default Hero;