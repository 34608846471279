import React, { useState, useEffect } from 'react';

import { useMasterContext } from '../contexts/MasterContext';

import ProjectGrid from '../components/Projects/ProjectGrid';
import ProjectsLoading from '../components/Projects/ProjectsLoading';
import BlogPosts from '../components/Home/BlogPosts';
import H2 from '../components/UI/H2';
import H3 from '../components/UI/H3';

const Projects = () => {

    const { showBlogPosts } = useMasterContext();

    const [projectsLoading, setProjectsLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setProjectsLoading(false);
        }, 2000);
    }, []);

    return (
        <div class="animate-fade">
            {showBlogPosts && <BlogPosts />}
            <div class="flex flex-col items-center justify-between space-y-16 mb-16">
                <div class="px-2 max-w-5xl mx-auto w-full flex flex-col items-center justify-between mt-12 space-y-12">
                    <H3 text="Projects" />
                    <H2 text="Jami's work lies at the intersection of law, journalism, and criminal justice" />
                </div>
                {projectsLoading ? (
                    <ProjectsLoading />
                ) : (
                    <ProjectGrid />
                )}
            </div>
        </div>
    );
};

export default Projects;
