import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { MasterContextProvider } from './contexts/MasterContext';

// Pages imports
import Home from './pages/Home';
import Career from './pages/Career';
import Projects from './pages/Projects';
import ProjectDetails from './pages/ProjectDetails';
import Contact from './pages/Contact';
import Book from './pages/Book';
import Appearances from './pages/Appearances';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import Error from './pages/Error';

// Components imports
import Header from './components/Header';
import Footer from './components/Footer';

import './App.css';

const App = () => {
  return (
    <Router>
      <MasterContextProvider>
        <div className="overflow-x-hidden relative">
          <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/timeline" element={<Career />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/projects/:tag" element={<ProjectDetails />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/dream-interrupted" element={<Book />} />
            <Route path="/appearances" element={<Appearances />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:tag" element={<BlogPost />} />
            <Route path="*" element={<Error />} />
          </Routes>
          <Footer />
        </div>
      </MasterContextProvider>
    </Router>
  );
};

export default App;
