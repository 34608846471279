import React, { useEffect } from 'react';

import { useMasterContext } from '../contexts/MasterContext';

import CentralEmergenceUnderline from './UI/CentralEmergenceUnderline';
import Button from './UI/Button';

const Footer = () => {

    let { blogPosts, populateBlogPosts } = useMasterContext();

    useEffect(() => {
        populateBlogPosts();
    }, []);

    const sections = [
        { section: 'Sitemap',
            links: [
                { name: 'Home', href: '/' },
                { name: 'About', href: '/about' },
                { name: 'Projects', href: '/projects' },
                { name: 'Timeline', href: '/timeline' },
                { name: 'Book', href: '/dream-interrupted' },
                { name: 'Blog', href: '/blog' },
                { name: 'Appearances', href: '/appearances' },
                { name: 'Contact', href: '/contact' },
            ]
        },
        { section: 'Social',
            links: [
                { name: 'Twitter', href: 'https://twitter.com/jamifloyd', target: '_blank' },
                { name: 'Instagram', href: 'https://www.instagram.com/jamifloyd/', target: '_blank' },
                { name: 'Facebook', href: 'https://www.facebook.com/jamifloyd', target: '_blank' },
                { name: 'LinkedIn', href: 'https://www.linkedin.com/in/jami-floyd-5a312a25/', target: '_blank' },
                { name: 'YouTube', href: 'https://www.youtube.com/@JamiFloydChannel', target: '_blank' },
                { name: 'Medium', href: 'https://medium.com/@jamifloyd', target: '_blank' },
            ]
        }
    ];

    // only map the first 5 post links
    if (blogPosts) {
        blogPosts = blogPosts.slice(0, 5);
    }

    blogPosts && sections.push({
        section: 'Blog Posts',
        links: blogPosts.map((post) => ({
            name: post.title,
            href: post.link,
            target: '_blank'
        }))
    });

    return (
        <footer class="bg-white py-12 xl:pt-24 relative font-serif border-t">
            <div class="max-w-7xl px-4 d:px-0 mx-auto font-serif">
                <div class="grid grid-cols-2 md:grid-cols-3 gap-12 sm:gap-24 lg:grid-cols-5 xl:gap-24">
                    <div class="flex flex-col items-start justify-start col-span-2 space-y-16">
                        <div class="space-y-8">
                            <p class="text-lg text-gray-700">The official website of award-winning journalist, lawyer, and author Jami Floyd.</p>
                            <p class="text-lg text-gray-700">If you'd like to get in contact with Jami or have general inquires about her work, please get in touch below.</p>
                        </div>
                        <div class="w-full flex items-center justfiy-center sm:justify-start">
                            <Button href="/contact" text="Get in touch" color="gray" />
                        </div>
                    </div>

                    {sections.map((section) => (
                        <div>
                            <h3 className="mb-8 text-lg text-gray-700">{section.section}</h3>
                            <ul className="text-lg font-regular">
                                {section.links.map((link) => (
                                    <li className="mb-4">
                                        <CentralEmergenceUnderline
                                            href={link.href}
                                            text={link.name}
                                            size="lg"
                                            target={link.target}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}       
                </div>

                <div class="border-[0.5px] w-full my-12" />

                <div class="flex flex-row items-center justify-end">
                    <p class="text-sm text-gray-700 text right">© 2024 Jami Floyd Media, LLC. All rights reserved.</p>
                </div>
            </div>
            
        </footer>

    );
};

export default Footer;