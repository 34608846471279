import React from 'react';

import H4 from '../UI/H4';
import CentralEmergenceUnderline from '../UI/CentralEmergenceUnderline';

const Socials = () => {
  const socials = [
    {
      name: 'Twitter',
      cta: 'Follow',
      href: 'https://twitter.com/jamifloyd'
    },
    {
      name: 'Instagram',
      cta: 'Follow',
      href: 'https://www.instagram.com/jamifloyd/'
    },
    {
      name: 'Facebook',
      cta: 'Follow',
      href: 'https://www.facebook.com/jamifloyd'
    },
    {
      name: 'LinkedIn',
      cta: 'Connect',
      href: 'https://www.linkedin.com/in/jami-floyd-5a312a25/'
    },
    {
      name: 'YouTube',
      cta: 'Subscribe',
      href: 'https://www.youtube.com/@JamiFloydChannel'
    },
    {
      name: 'Medium',
      cta: 'Subscribe',
      href: 'https://jamifloyd.medium.com/'
    },
  ];

  return (
    <div class="border-t py-24">
      <div class="max-w-7xl px-4 d:px-0 mx-auto font-serif">
        <div class="grid grid-cols-1 space-y-8 lg:space-y-0 lg:space-x-8 items-center justify-items-stretch">
          <div class="flex flex-col items-center justify-start h-full col-span-5 space-y-16 max-w-5xl mx-auto">
            <H4 text="Social Media" />
            <div class="w-full grid grid-cols-3 md:grid-cols-6 gap-y-16 gap-x-16">
              {socials.map((social, index) => {
                const border = index !== 0 ? 'md:border-l' : '';
                const paddingRight = index !== socials.length - 1 ? 'pr-0 md:pr-4' : '';
                const paddingLeft = index !== 0 ? 'pr-0 md:pl-4' : '';
                return (
                  <div
                    key={index}
                    class={`${border} ${paddingRight} ${paddingLeft} w-full md:w-auto`}
                  >
                    <div class="flex flex-col items-start justify-between h-full space-y-8">
                        <a class="text-2xl font-[500] font-serif hover:text-gray-700">{social.name}</a>
                        <a href={social.href} target="_blank" rel="noopener noreferrer">
                            <CentralEmergenceUnderline text={social.cta} size={"md"} />
                        </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Socials;
