import React, { useState, useEffect } from 'react';

import { useMasterContext } from '../contexts/MasterContext';

import Timeline from '../components/Career/Timeline';
import TimelineLoading from '../components/Career/TimelineLoading';
import BlogPosts from '../components/Home/BlogPosts';
import H2 from '../components/UI/H2';
import H3 from '../components/UI/H3';

const Career = () => {

    const { showBlogPosts } = useMasterContext();

    const [timelineLoading, setTimelineLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setTimelineLoading(false);
        }, 2000);
    }, []);

    return (
        <div class="animate-fade">
            {showBlogPosts && <BlogPosts />}
            <div class="max-w-7xl px-4 d:px-0 mx-auto space-y-12 font-serif pb-24">
                <div class="max-w-5xl mx-auto flex flex-col items-center justify-between mt-12 space-y-12">
                    <H3 text="Timeline" />
                    <H2 text="Travel through Jami's career & personal journey" />
                </div>
                <div class="grid grid-cols-1 lg:grid-cols-12 space-y-8 lg:space-y-0 lg:space-x-8 items-center justify-items-stretch">
                    {timelineLoading ? (
                        <TimelineLoading />
                    ) : (
                        <Timeline />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Career;