import React from 'react';


import H2 from '../UI/H2';
import H3 from '../UI/H3';

const About = () => {
    return (
        <div class="border-t">
            <div class="max-w-7xl px-4 d:px-0 mx-auto font-serif">
                <div class="py-24 grid grid-cols-1  space-y-8 lg:space-y-0 lg:space-x-8 items-center justify-items-stretch">
                    <div class="flex flex-col items-center justify-center w-full h-full col-span-5 space-y-16 max-w-5xl mx-auto">
                        <H3 text="About Jami" />
                        <H2 text="Jami is an award-winning author, and civil rights advocate" />
                        <p class="text-gray-700 text-center text-lg">Jami Floyd is an award-winning civil rights journalist, podcaster, legal analyst, and author. Her broadcast experience spans network, cable, and local television, as well as broadcast radio and digital media. She is nationally and internationally regarded as an expert on race, law, and communications, especially where they intersect. Jami has interviewed countless players on the American landscape but still considers her interviews with Myrlie Evers-Williams, widow of the slain civil rights leader Medgar Evers, her most meaningful. Before journalism, Jami received her JD from the UC Berkeley Law School. She worked as a White House Fellow in the Clinton Administration, served as a teaching fellow at Stanford Law School while she pursued a master's degree in law, and worked as a public defender in San Francisco. She currently lives in New York City with her husband, two children, and a menagerie of animals.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
