const projectsContent = [
    {
        title: "Jack Was Kind",
        blurb: "Consulting producer for Jack Was Kind, an AFO production",
        details: "Originally produced by All For One Theater in association with Jami Floyd for virtual audiences, Jack Was Kind is a one-woman show written and performed by Tracy Thorne and directed by Nick Cotz, with its world Stage Premiere at the Irish Repertory in November 2022.The show imagines the internal life of a woman married to a prominent judge as he reaches for the role of a lifetime–the US Supreme Court. Painfully human and based on a real-life American event that will affect the country for generations to come, this intimate confessional story examines deep-seeded issues of privilege and complicity at the root of American sexual politics. Just before opening, AFO was shuttered for COVID and pivoted to zoom performances in Spring 2020, adding a series of conversations integral to the virtual performance. Jami produced these conversations, moderating several, together with AFO's Josh Adam Ramos for a theatrical experience that gave remote audiences an emotional punch, but also an artistic release.",
        role: "Consulting Producer",
        asset: "jack_was_kind.jpg",
    },
    {
        title: "Richard III",
        blurb: "Consulting Producer for Richard III, a WNYC & Public Theater production",
        details: "As consulting producer for this third season of free Shakespeare on the Radio, in partnership with the Public Theater, Jami Floyd again serves as a consulting producer at the center of the creative team, helping to open lines of communication between the teams NYPR and the Public Theater.",
        role: "Consulting Producer",
        asset: "richard_iii.jpg",
    },
    {
        title: "HB95: A Birthday Celebration Honoring the Life and Work of Harry Belafonte",
        blurb: "Producer for HB95, a Town Hall Theatre production",
        details: "Jami Floyd teams up with multimedia artist Carrie Mae Weems and Gina Belafonte, Harry Belafonte's daughter and the Founder and Executive Director of Sankofa.org, a social justice organization that elevates the voices of disenfranchised people, and promotes peace, justice, and equality. Together they collaborate to commemorate HB95, a birthday celebration honoring the life and work of Harry Belafonte and to create a larger project that will memorialize the event for generations to come.",
        role: "Producer",
        asset: "hb95.jpg",
    },
    {
        title: "9/11 and the Rise of the NYPD",
        blurb: "Senior Editor & Coordinating Producer for 9/11 and the Rise of the NYPD, a WNYC production",
        details: "This 20th Anniversary Series takes an in-depth look at the ways in which the September 11th attacks fundamentally changed the NYPD, its approach to policing, and our collective relationship to the largest police force in the country. As Senior Editor for the Race & Justice Unit, Jami Floyd helped conceive this project, and coordinated a team of two dozen reporters, editors and engineers to produce this unique take on the September 11 anniversary in the context of the current criminal justice reform movement.",
        role: "Senior Editor & Coordinating Producer",
        asset: "911_and_the_rise_of_the_nypd.jpg",
    },
    {
        title: "The Hate Crime Conundrum",
        blurb: "Legal contributor for The Hate Crime Conundrum, a WNYC & The Atlantic production",
        details: "To evaluate the underpinnings of our criminal justice system, WNYC's Legal Editor Jami Floyd assisted the Experiment reporter Tracie Hunte as they examine our fraught history with hate crime laws and prosecutions. For this episode, she joined contributors Saida Grundy, assistant professor of sociology and African American studies at Boston University, Jason Wu, co-chair of the LGBTQ advocacy group GAPIMNY, Jeannine Bell, professor at Indiana University's Maurer School of Law, and Sunayana Dumala, the founder of Forever Welcome. The 'Hate Crime Conundrum', in partnership with The Atlantic, was first broadcast on July 22, 2021, and rebroadcast on January 3, 2022.",
        role: "Legal Editor & Contributor",
        asset: "the_hate_crime_conundrum.jpg",
    },
    {
        title: "The Vanishing of Harry Pace",
        blurb: "Senior Editor & Consulting Producer for The Vanishing of Harry Pace, an OSM Audio & WNYC Studios production",
        details: "One hundred years after the first Black owned record label launched in the United States, OSM Media and Radio Lab explore the mysterious life of Harry Pace, the brilliant businessman behind the music who had an outsized influence on 20th century America before he slipped into oblivion. New York Public Radio's Senior Editor for Race & Justice, Jami Floyd serves as a consulting producer on the project, as well as a voice throughout the 6-part podcast. This series has been nominated for an International Documentary Association (IDA) award.",
        role: "Senior Editor & Consulting Producer",
        asset: "the_vanishing_of_harry_pace.jpg",
    },
    {
        title: "WNYC's Annual MLK Jr. Day Celebration at the Apollo",
        blurb: "Annual Host, Producer for WNYC's Annual MLK Jr. Day Celebration at the Apollo, a WNYC & The Apollo Theatre production",
        details: "Every year WNYC and The Apollo present a Dr. Martin Luther King, Jr. Day celebration to honor MLK, his life, and legacy. Jami Floyd has been a host and producer of these live Sunday events at the historic Apollo theater, later broadcast on the official Mondy Martin Luther King Day holiday. Each year performers, panelists, and a live audience examine together a different theme around Dr. King's work and the Civil Rights Movement, with a view toward bringing new life and continued meaning to the struggle for racial and social justice.",
        role: "Host & Producer",
        asset: "wnycs_annual_mlk_jr_day_celebration_at_the_apollo.jpg",
    },
    {
        title: "24 Minutes in Mott Haven",
        blurb: "Creator and Executive Producer for 24 Minutes in Mott Haven, a Gothamist & WNYC Studios production",
        details: "After the murder of George Floyd, a movement for criminal justice reform swept the country. One protest in a predominantly Black and brown neighborhood in the Bronx was later cited by human rights Human Rights Watch as a violation of human rights, civil rights protections of the US Constitution and the NYPD's own Patrol Guide. As a native New Yorker and attorney, Jami Floyd wanted to lay bare the systems of inequity that led to this clash between protesters and police. As Senior Editor of WNYC's Race & Justice Unit, she conceived: 24 Minutes in Mott Haven: a multimedia project in which 24 courageous New Yorkers relive their experience on the ground that night. Their harrowing accounts underscore the ways in which our criminal justice system is not working for the very people it purports to protect.",
        role: "Creator and Executive Producer",
        asset: "24_minutes_in_mott_haven.jpg",
    },
    {
        title: "Blindspot: Tulsa Burning",
        blurb: "Editor for Blindspot: Tulsa Burning, a WNYC Studios & The History Channel production",
        details: "As the 100th Anniversary of the Tulsa Massacre approached, Jami Floyd proposed to WNYC Studios a podcast that would revisit the life of one Black man who had served as a beacon in the burgeoning Greenwood community of Tulsa and who has been a role model for young journalists ever since - AJ Smitherman. Working with KOSU and in partnership with the History Channel, WNYC Studios presents Blindspot: Tulsa Burning. This podcast received the 2022 duPont-Columbia Awards Honoring the Best of Journalism and is a nominee for Outstanding Podcast at the 53rd annual NAACP Image Awards.",
        role: "Editor",
        asset: "blindspot_tulsa_burning.jpg",
    },
    {
        title: "No Excuse for Abuse",
        blurb: "Contributor & Expert Reader for No Excuse for Abuse, a PEN America production",
        details: "Online abuse—from hateful slurs to violent threats—is a pervasive and growing problem. Writers and journalists who identify as women, BIPOC, LGBTQIA+, and members of religious or ethnic minorities are especially susceptible. To ensure that social media becomes safer, more open, and more equitable for all users, PEN America prepared this report to propose concrete, actionable changes that social media companies can make to protect people from online abuse—without jeopardizing free expression. Jami Floyd served as an expert contributor.",
        role: "Contributor & Expert Reader",
        asset: "no_excuse_for_abuse.jpg",
    },
    {
        title: "More Perfect",
        blurb: "Contributing Legal Editor for More Perfect, a WNYC Studios & Radio Lab production",
        details: "More Perfect is about the Supreme Court decisions that shape everything from marriage and money to public safety and sex. We know these are very important decisions we should all pay attention to – but they often feel untouchable and even unknowable. Radiolab's first ever spin-off series, More Perfect, connects you to the decisions made inside the Court's hallowed halls, and explains what those rulings mean for 'We The People' who exist far from the bench. With the help of its Contributing Editors, including Jami Floyd, More Perfect bypasses the wonkiness and tells stories behind some of the court's biggest rulings.",
        role: "Contributing Legal Editor",
        asset: "more_perfect.jpg",
    },
    {
        title: "Romeo y Julieta",
        blurb: "Consulting Producer for Romeo y Julieta, a WNYC & The Public Theatre production",
        details: "As consulting producer for this first-ever bilingual production of Shakespeare's Romeo & Juliet, Jami Floyd was a coordinating force at the center of the creative team for the second live Shakespeare on the Radio! For Romeo y Julieta, Jami opened lines of communication between NYPR and the Public Theater, produced bilingual radio conversations amongst the creatives including playwrights Ricardo Pérez González, Saheem Ali, Alison Stewart, and Brooke Gladstone, and mounted an international live event in the Greene Space hosted by breakout talent Rebeca Ibarra and showcasing Mr. Ali in New York City, Lupita Nyong'o from Lagos, and Shakespeare scholar Alfredo Michel Modenessi from Mexico City, along with many others.",
        role: "Consulting Producer",
        asset: "romeo_y_julieta.jpg",
    },
    {
        title: "Radio Rookies",
        blurb: "Senior Editor for Radio Rookies, a WNYC production",
        details: "With the launch of the Race & Justice Unit, Jami Floyd assumed responsibility for WNYC's longstanding and award-winning Radio Rookies at New York Public Radio. The initiative provides young people with the training and tools to create radio and digital stories about their communities. This season, under the umbrella of the Race & Justice Unit, Radio Rookies is focused on the impact of our criminal justice system on the lives of Black and brown youth across New York City. Under Jami's supervision, the program is training young people to produce radio documentaries and text pieces about the impact of policing, violence, and incarceration on their lives for broadcast nationwide. Carolina Hidalgo produces with production assistance from Jodi Malarbe.",
        role: "Senior Editor",
        asset: "radio_rookies.jpg",
    },
    {
        title: "Richard II",
        blurb: "Host & Producer for Richard II, a WNYC Studios & The Public Theatre production",
        details: "As COVID threatened the Public Theater's summer production of Richard II, WNYC conceived of a new and exciting way to bring Shakespeare in the Park to audiences when they needed it most: free Shakespeare on the Radio! Jami Floyd served as WNYC Studios host, as well as a producer on the radio side, for this first-ever production of Shakespeare on the Radio in partnership with the Public Theater.",
        role: "Host & Producer",
        asset: "richard_ii.jpg",
    },
    {
        title: "Attica: Fifty Years Later",
        blurb: "Senior Editor for Attica: Fifty Years Later, a WNYC & Gothamist production",
        details: "WNYC and Gothamist examine the long shadow of incarceration in the United States on the 50th anniversary of the Attica Prison Rebellion, the bloodiest prison uprising in U.S. history. Senior Editor for Race & Justice Jami Floyd leads a team of reporters to reexamine the the Attica uprising in the context of George Floyd's murder and the protest movement that followed. What has changed in New York State's prison system to improve the quality of life for inmates, what remains unaddressed half a century later, and what has been the impact of the rebellion on prison policy nationwide?",
        role: "Senior Editor",
        asset: "attica_fifty_years_later.jpg",
    },
    {
        title: "1968: Fifty Years Later",
        blurb: "Creator & Host of 1968: Fifty Years Later, a WNYC Radio production",
        details: "The WNYC newsroom revisits the watershed moments of 1968 to see what we can learn from them now. In a series that she conceived and curated, WNYC's All Things Considered host Jami Floyd speaks with historians, journalists, authors, student protesters, and others who participated in those events or have examined their significance.",
        role: "Creator & Host",
        asset: "1968_fifty_years_later.jpg",
    },
    {
        title: "The Other Box Project",
        blurb: "Creator & Executive Producer of The Other Box Project, a Jami Floyd & WNYC Studios production",
        details: "Jami Floyd conceived the Other Box Project (OBP) in 2014 to commemorate the 50th anniversary of the U.S. Supreme Court decision in Loving v. Virginia which declared all laws against interracial marriage unconstitutional. OPB is a series of public conversations around multiracial identity culminating in a museum exhibition of intimate portraits and op docs by the renowned artist Carrie Mae Weems.",
        role: "Creator & Executive Producer",
        asset: "the_other_box_project.jpg",
    },
    {
        title: "OJ: Made in America",
        blurb: "Consulting Producer for OJ: Made in America, an Ezra Edelman & ESPN Films production",
        details: "OJ: Made in America is Ezra Edelman's investigation into the broader context of the Trial of the 20th-century. Edelman and his team, including attorney and journalist Jami Floyd, not only fill in details about the case but offer illumination through the prism of race, domestic violence, the LAPD, and the criminal justice system, using the OJ Simpson story as a tool to better understand ourselves. Edelman, co-producer Caroline Waterlow and the OJ: Made in America team, won the Academy Award for best documentary of 2016.",
        role: "Consulting Producer",
        asset: "oj_made_in_america.jpg",
    },
];

export default projectsContent;