import React, { useState } from 'react';

const H3 = (props) => {

    const [isHovered, setIsHovered] = useState(false);

    return (
        <div 
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            class="col -space-y-0 items-center justify-center"
        >
            <h1 class="font-serif text-lg sm:text-xl text-gray-700 text-center font-bold">{props.text}</h1>
            <div class={`h-[1px] w-full ${!isHovered ? 'bg-gray-400' : 'bg-gray-700'} mx-auto rounded-full transition-colors duration-400`}></div>
        </div>
    );
};

export default H3;