const timelineContent = [
    {
        date: "2022",
        title: "Jami Floyd Media, LLC",
        description: "Launches a media company that seeks to tell diverse stories that champion Black excellence, in which women are the focus, and narratives that explore the LGBTQ+ experience. Our stories our projects span social justice, politics, investigations, and crime, and we run the gamut from television, and film, to theater and print.",
        quote: "",
        link: "https://firm.jamifloyd.com/",
    },
    {
        date: "2022",
        title: "Dream Interupted",
        description: "Little Brown announces book deal with Jami Floyd for the forthcoming book, Dream Interrupted: Searching for Thurgood Marshall and the Soul of a Nation.",
        quote: "",
        link: "https://www.publishersweekly.com/pw/by-topic/industry-news/book-deals/article/88921-book-deals-week-of-april-04-2022.html",
    },
    {
        date: "2022",
        title: "HB95 Project",
        description: "Embarks on a new project to support Gina Belafonte as she celebrates the life and legacy of her father Harry Belafonte and Sankofa.org.",
        quote: "",
        link: "",
    },
    {
        date: "2020",
        title: "Launch of the Race and Justice Unit",
        description: "Jami launches WNYC's Race & Justice Unit after a visit to the Legacy Museum and National Memorial for Peace and Justice in Montgomery, AL.",
        quote: "",
        link: "",
    },
    {
        date: "2017",
        title: "O.J. Simpson: Made in America",
        description: "Jami is featured in the Oscar-winning documentary O.J. Simpson: Made in America. Jami serves as Consulting Producer for director Ezra Edelman.",
        quote: "",
        link: "https://tv.apple.com/us/show/oj-made-in-america/umc.cmc.45qq1f626v4p5xhbt2v1dnzvq",
    },
    {
        date: "2017",
        title: "Other Box Project on Love Race & Identity",
        description: "Jami launches a new project on in collaboration with celebrated American artist Carrie Mae Weems.",
        quote: "",
        link: "",
    },
    {
        date: "2015",
        title: "Joins WNYC as Host, All Things Considered",
        description: "Jami joins WNYC as Host of All Things Considered, the station's flagship afternoon drive-time program.",
        quote: "",
        link: "",
    },
    {
        date: "2015",
        title: "Jami's father, James Floyd, passes away",
        description: "Jami's father, James Floyd, passes away at the age of 88.",
        quote: "",
        link: "",
    },
    {
        date: "2013",
        title: "Jami joins Al Jazeera America",
        description: "Founding member of Al Jazeera America in New York City where Jami serves as Chief Legal Contributor covering the shooting death of Trayvon Martin, Ferguson, and the disappearance of Malaysian Flight 370.",
        quote: "",
        link: "",
    },
    {
        date: "2011",
        title: "MLK Jr. Day Celebration",
        description: "Begins a decade of co-hosting the annual MLK Celebration at the Apollo Theater in Harlem, sharing the stage with the Rev. William Barber, Rep. Jim Clyburn, Bishop Michael Curry, Professor Nikole Hannah-Jones, rapper Talib Kweli, activist-journalist Jose Antonio Vargas, linguist John McWhorter, Poet Laureate Natasha Trethewey, and the American artist Carrie Mae Weems.",
        quote: "",
        link: "",
    },
    {
        date: "2010",
        title: "Jami joins MSNBC",
        description: "Jami joins MSNBC as a Legal Analyst and Contributor.",
        quote: "",
        link: "",
    },
    {
        date: "2002",
        title: "Birth of Jax",
        description: "Jami and Kurt welcome their second child, Jax, into the world.",
        quote: "",
        link: "https://www.jaxfloyd.com",
    },
    {
        date: "2000",
        title: "ABC News: Law & Justice Unit",
        description: "At ABC News Jami And her colleagues start the Law & Justice Unit serving all shows and platforms at the network on major stories such as Bush v. Gore and the September 11 attacks.",
        quote: "",
        link: "",
    },
    {
        date: "1999",
        title: "Birth of Joelle",
        description: "Jami and Kurt welcome their first child, Joelle, into the world.",
        quote: "",
        link: "",
    },
    {
        date: "1998",
        title: "Jami Joins ABC News",
        description: "Jami joins ABC News, first based in Chicago and then New York. Early stories include the Capitol Hill Shooting, the Columbine school shootings, and the plane crash that killed John Kennedy, Jr., his wife and sister-in-law.",
        quote: "",
        link: "",
    },
    {
        date: "1997",
        title: "People v. OJ Simpson",
        description: "Legal Analyst for CBS News and the only Network Analyst to predict Simpson would be found Not Guilty. Jami covered the criminal trial,1994-1995, and the civil trial,1996-1997, in which Simpson is found liable for the murders of Ron Goldman and Nicole Brown Simpson.",
        quote: "",
        link: "",
    },
    {
        date: "1996",
        title: "Jami interviews Myrlie Evers-Williams",
        description: "Jami reports on the Mississippi retrial of Byron de la Beckwith for the murder of civil rights leader Medgar Evers. She still considers her interview with Medgar's widow, Myrlie Evers-Williams, the most meaningful in her career.",
        quote: "",
        link: "",
    },
    {
        date: "1996",
        title: "Jami joins Court TV",
        description: "Jami lands first full-time television job at the new cable news outlet Court TV. From there, she moves to CBS News working with Ed Bradley, Dana King, and Dan Rather.",
        quote: "",
        link: "",
    },
    {
        date: "1996",
        title: "Stanford Law School Graduation",
        description: "As a teaching fellow at Stanford, Jami receives masters in law and develops a new interdisciplinary curriculum around law and communications.",
        quote: "",
        link: "",
    },
    {
        date: "1994",
        title: "First Trip to Africa",
        description: "Visits South Africa, Botswana, Zimbabwe, and Malawi, as well as the Mozambiquan border to meet child refugees of the civil war.",
        quote: "",
        link: "",
    },
    {
        date: "1993",
        title: "White House Fellowship Appointment",
        description: "Joins the Clinton White House and works for Hillary Clinton on health care communications and Al Gore on crime policy, judicial vetting, and community empowerment.",
        quote: "",
        link: "",
    },
    {
        date: "1992",
        title: "Jami marries Kurt Flehinger",
        description: "Jami and Kurt are married in San Fransisco, CA, at the Lion Street Steps. The couple begin their new life together in Oakland, CA.",
        quote: "",
        link: "",
    },

    {
        date: "1991",
        title: "Admittance to the California Bar",
        description: "Jami is admitted to the California Bar.",
        quote: "",
        link: "",
    },
    {
        date: "1990",
        title: "Jami joins Morrison & Foerster",
        description: "Jami joins Morrison & Foerster as an associate in the Litigation Department, practicing criminal, First Amendment, and death penalty law.",
        quote: "",
        link: "",
    },
    {
        date: "1989",
        title: "Law Clerk to the Honorable Allen E. Broussard",
        description: "Jami serves as law clerk to the Honorable Allen E. Broussard, Associate Justice, California Supreme Court.",
        quote: "Justice Broussard was like a second father to me. He believed in me and took me under his wing. In 1992 he even performed my wedding ceremony. I think of him every day.",
        link: "",
    },
    {
        date: "1989",
        title: "Jami's Law School Graduation",
        description: "Jami graduates from the University of California, Berkeley School of Law with honors. Jami was a member of the California Law Review, and a Teaching Assistant for Legal Research & Writing. In her salutatory address to her graduating class at the Greek Theater:",
        quote: "It is for us to regain the human spirit of the law. It is for us to deal with problems on a human scale. It is for us to realize that to be a real lawyer is to concern ourselves with people and with the things that hold people together or drive them apart. The law must be for us a human profession.",
        link: "",
    },
    {
        date: "1986",
        title: "Jami's College Graduation",
        description: "Jami graduates from Binghamton University. Jami served as Ombudsperson & contributor, student newspaper, The Pipe Dream. She was a Teaching Assistant for World Politics & Model UN, a Resident Assistant, College-in-the-Woods, and a Disc Jockey for WHRW.",
        quote: "",
        link: "",
    },
    {
        date: "1982",
        title: "Jami's High School Graduation",
        description: "Jami graduates from St. Vincent Ferrer High School, in Manhattan, New York City. Among other achievements, Jami was Editor in Chief of the student newspaper, The Trumpet, and the first Black Class President, class of 1982.",
        quote: "There were only two Black girls in the entire school. When I was a senior, a new African American girl came into the freshman class, so there were three of us. But this was New York City, so where were all the Black kids? There was a lot of racial tension at school. But I had friends. And teachers who believed in me —they changed my life.",
        link: "",
    },
    {
        date: "1978",
        title: "Jami's Middle School Graduation",
        description: "Jami graduates from St. Joseph's School, in Greenwich Village, New York City.",
        quote: "",
        link: "",
    },
    {
        date: "1972",
        title: "Jami's Eight Birthday",
        description: "Jami has her memorable eighth birthday on the Lower East Side.",
        quote: "My parents really saved up for that party. My friends were all dressed up and all the parents came, too. I still think this was the best party I've ever had.",
        link: "",
    },
    {
        date: "1967",
        title: "Loving v. Virginia",
        description: "The Supreme Court rules in Loving v. Virginia that laws banning interracial marriage are unconstitutional. Jami is now three years old.",
        quote: "",
        link: "",
    },
    {
        date: "1964",
        title: "Jami Floyd is born in New York City",
        description: "Jami Floyd is born in New York City to James and JoAnn Floyd. Initially nameless, Jami was, for her first two weeks, lovingly dubbed, Baby Grimm.",
        quote: "",
        link: "",
    },
    {
        date: "1956",
        title: "James & JoAnn Floyd Are Married",
        description: "Despite interracial marriage being illegal in half of U.S. States, James Floyd and JoAnn Crawford are married in Chicago.",
        quote: "",
        link: "",
    },
];

export default timelineContent;
