import React from 'react';

import { useMasterContext } from '../contexts/MasterContext';

import BlogPosts from '../components/Home/BlogPosts';
import CTA from '../components/Contact/CTA';
import Socials from '../components/Contact/Socials';
import Testemonials from '../components/Contact/Testemonials';
// import Form from '../components/Contact/Form';

const Contact = () => {

    const { showBlogPosts } = useMasterContext();

    return (
        <div class="animate-fade">
            {showBlogPosts && <BlogPosts />}
            <div class="py-24 space-y-24">
                <CTA />
                <Socials />
                <Testemonials />
                {/* <Form /> */}
            </div>
        </div>

    );
};

export default Contact;