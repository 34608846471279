const parseHTML = (htmlString) => {
    const div = document.createElement('div');
    div.innerHTML = htmlString;
  
    // Get an array of child nodes from the div
    const childNodes = Array.from(div.childNodes);
  
    // Filter out non-paragraph nodes
    const paragraphs = childNodes.filter(node => node.nodeName === 'P');

    for (let i = 0; i < paragraphs.length; i++) {
        // We need to remove &nbsp; from the text, inputting a space instead.
        paragraphs[i].innerHTML = paragraphs[i].innerHTML.replace(/&nbsp;/g, ' ');
        paragraphs[i].innerHTML = paragraphs[i].innerHTML.replace(/&lt;/g, '<');
        paragraphs[i].innerHTML = paragraphs[i].innerHTML.replace(/&gt;/g, '>');
        paragraphs[i].innerHTML = paragraphs[i].innerHTML.replace(/&amp;/g, '&');
        paragraphs[i].innerHTML = paragraphs[i].innerHTML.replace(/&quot;/g, '"');
        paragraphs[i].innerHTML = paragraphs[i].innerHTML.replace(/&apos;/g, "'");
        paragraphs[i].innerHTML = paragraphs[i].innerHTML.replace(/&cent;/g, '¢');
        paragraphs[i].innerHTML = paragraphs[i].innerHTML.replace(/&pound;/g, '£');
        paragraphs[i].innerHTML = paragraphs[i].innerHTML.replace(/&yen;/g, '¥');
        paragraphs[i].innerHTML = paragraphs[i].innerHTML.replace(/&euro;/g, '€');
        paragraphs[i].innerHTML = paragraphs[i].innerHTML.replace(/&copy;/g, '©');
        paragraphs[i].innerHTML = paragraphs[i].innerHTML.replace(/&reg;/g, '®');

    }

    // We need to handle a tags in the inner html of each paragraph
    for (let i = 0; i < paragraphs.length; i++) {
        const html = paragraphs[i].innerHTML;
        const aTags = html.match(/<a.*?>(.*?)<\/a>/g);
        if (aTags) {
            for (let j = 0; j < aTags.length; j++) {
                const aTag = aTags[j];
                const href = aTag.match(/href="(.*?)"/g)[0].replace('href="', '').replace('"', '');
                const text = aTag.match(/>(.*?)</g)[0].replace('>', '').replace('<', '');
                paragraphs[i].innerHTML = paragraphs[i].innerHTML.replace(aTag, `<a class="text-primary-950 underline" href="${href}">${text}</a>`);
            }
        }
        
    }
    // Return the array of paragraphs as JSX elements
    return paragraphs.map((paragraph, index) => (
        <>
            <p key={index} class="w-full text-left text-lg">{paragraph.innerHTML}</p>
            <div class="my-3"></div>
        </>
      
    ));
  };
  
  export default parseHTML;
  