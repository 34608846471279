import React from 'react';

import Button from '../UI/Button';

const Contact = () => {
    return (
        <div className="border-t">
            <div className="max-w-7xl px-4 d:px-0 mx-auto font-serif">
                <div className="py-24">
                    <div className="flex flex-col items-center justify-center h-full col-span-5 space-y-8 max-w-5xl mx-auto">
                        <p className="text-gray-700 text-2xl font-[500] text-center">
                            Reach out to Jami to propose collaborations, projects, or just to chat!
                        </p>
                        <Button text="Contact Jami" href="/contact" color="orange" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;