import React, { useState } from 'react';

import { useMasterContext } from '../contexts/MasterContext';

import Logo from './UI/Logo';
import CentralEmergenceUnderline from './UI/CentralEmergenceUnderline';

const Header = () => {

    const { setShowBlogPosts, populateBlogPosts } = useMasterContext();

    const pages = [
        {
            name: 'Home',
            link: '/'
        },
        {
            name: 'Projects',
            link: '/projects'
        },
        {
            name: 'Timeline',
            link: '/timeline'
        },
        {
            name: 'Dream Interrupted',
            link: '/dream-interrupted'
        },
        {
            name: 'Blog',
            link: '/blog'
        },
        {
            name: 'Appearances',
            link: '/appearances'
        },
        {
            name: 'Contact',
            link: '/contact'
        },
    ];

    const handleShowBlogPostsClick = () => {
        populateBlogPosts();
        setShowBlogPosts(true);
    };

    const [showMenu, setShowMenu] = useState(false);
    const [isAPageHovered, setIsAPageHovered] = useState(false);

    const handleShowMenuClick = () => {
        showMenu ? setShowMenu(false) : setShowMenu(true);
    };

    return (
        <>
                <div class="sticky border-b">
                    <div class="max-w-7xl px-4 d:px-0 mx-auto font-serif">
                        <div class="py-4 grid grid-cols-2 md:grid-cols-3 items-center justify-center">
                            <a href="/" class="cursor-pointer">
                                <Logo />
                            </a>

                            <div class="hidden md:flex flex-row items-center justify-between space-x-8">
                                <CentralEmergenceUnderline text="Home" href="/" />
                                <CentralEmergenceUnderline text="Projects" href="/projects" />
                                <CentralEmergenceUnderline text="Timeline" href="/timeline" />
                                <CentralEmergenceUnderline text="Appearances" href="/appearances" />
                                <CentralEmergenceUnderline text="Book" href="/dream-interrupted" />
                            </div>

                            <div class="flex flex-row items-center w-full justify-end space-x-4">
                                <div onClick={handleShowBlogPostsClick} class="hidden sm:flex flex-row items-center justify-between space-x-2 cursor-pointer text-gray-700 hover:text-gray-600">
                                    <svg fill="currentColor" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12" />
                                    </svg>
                                    <a class="hidden md:block text-lg text-gray-700 hover:text-gray-500">Blog Posts</a>

                                </div>
                                <div onClick={handleShowMenuClick} class="flex sm:hidden flex-row items-center justify-between space-x-2 cursor-pointer text-gray-700 hover:text-gray-600">
                                    <svg fill="currentColor" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12" />
                                    </svg>
                                    <a class="hidden md:block text-lg text-gray-700 hover:text-gray-500">Blog Posts</a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showMenu && (
                    <div class="fixed p-12 top-0 left-0 z-50 w-screen h-screen overflow-y-scroll flex items-start justify-center bg-white font-serif animate-fade animate-duration-200">
                        <div class="max-w-7xl mx-auto w-full flex flex-col items-center justify-end">
                            <div class="flex flex-row items-center justify-end w-full">
                                <div onClick={() => setShowMenu(false)} class="cursor-pointer text-gray-700 hover:text-gray-600">
                                    <svg fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" class="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                            </div>
                            <div 
                                onMouseEnter={() => setIsAPageHovered(true)}
                                onMouseLeave={() => setIsAPageHovered(false)}
                                class="flex flex-col items-center justify-center space-y-12 py-16"
                            >
                                {pages.map((page, index) => (
                                    <ul key={index} class={`inline text-center space-x-2 text-4xl text-bold sm:text-5xl ${isAPageHovered ? 'text-gray-500' : 'text-gray-900'}  hover:text-gray-900 transition-colors duration-400`}>
                                        <a href={page.link} class="text-center animate-fade-up">{page.name}</a>
                                    </ul>
                                ))}
                            </div>

                        </div>
                    </div>

                )}
                
        </>
    );
};

export default Header;