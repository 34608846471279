import { createContext, useContext, useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import retrieveContent from "../functions/retrieveContent";
import retrieveBlogPosts from "../functions/blog/retrieveBlogPosts";

import publishContent from "../functions/publishContent";

export const MasterContext = createContext();

export const useMasterContext = () => useContext(MasterContext);

export const MasterContextProvider = ({ children }) => {

    const navigate = useNavigate();

    // Blog Posts Related Handlers
    const [showBlogPosts, setShowBlogPosts] = useState(false);
    const [blogPosts, setBlogPosts] = useState(null);
    const [selectedBlogPost, setSelectedBlogPost] = useState(null);

    const populateBlogPosts = async () => {
        const blogPosts = await retrieveBlogPosts();
        setBlogPosts(blogPosts);
    };

    const handleBlogPostClick = ( post ) => {
        setSelectedBlogPost(post);

        const slug = post.title.split(' ').join('-').toLowerCase().replace(/[^\w-]+/g, '');
        navigate(`/blog/${slug}`);
        window.scrollTo(0, 0);
    };

    // Projects Related Handlers
    const [selectedProject, setSelectedProject] = useState(null);

    const handleProjectClick = ( project ) => {
        setSelectedProject(project);
        const slug = project.title.split(' ').join('-').toLowerCase().replace(/[^\w-]+/g, '');
        navigate(`/projects/${slug}`);
        window.scrollTo(0, 0);
    };
    
    // Master, site-wide content storage
    const [content, setContent] = useState(null);
    const [isContentLoading, setIsContentLoading] = useState(false);

    const populateContent = async () => {
        setIsContentLoading(true);
        const content = await retrieveContent();
        window.location.reload();
        setContent(content);
        localStorage.setItem('websiteContent', JSON.stringify(content)); // Store content in local storage
        setIsContentLoading(false);
    };

    useEffect(() => {
        const storedContent = localStorage.getItem('websiteContent');
        if (storedContent) {
            setContent(JSON.parse(storedContent));
        } else {
            populateContent();
        }
    }, []);

    const value = {

        showBlogPosts,
        setShowBlogPosts,

        blogPosts,
        setBlogPosts,

        selectedBlogPost,
        setSelectedBlogPost,

        selectedProject,
        setSelectedProject,

        content,
        setContent,

        isContentLoading,
        setIsContentLoading,

        populateBlogPosts,
        handleBlogPostClick,
        handleProjectClick,
        populateContent,

    };

    return (
        <MasterContext.Provider value={value}>
            {children}
        </MasterContext.Provider>
    )
};