import React, { useState, useEffect } from 'react';

import { useMasterContext } from '../contexts/MasterContext';

import BlogPosts from '../components/Home/BlogPosts';
import H2 from '../components/UI/H2';

const ProjectDetails = () => {

    const { showBlogPosts, selectedProject } = useMasterContext();

    const [title, setTitle] = useState('');
    const [blurb, setBlurb] = useState('');
    const [details, setDetails] = useState('');
    const [contribution, setContribution] = useState('');
        
    useEffect(() => {
        if (selectedProject) {
            setTitle(selectedProject.title);
            setBlurb(selectedProject.blurb);
            setDetails(selectedProject.details);
            setContribution(selectedProject.role);
        }
    }, [selectedProject]);

    return (
        <div class="animate-fade">
            {selectedProject && (
                <div class="max-w-7xl px-4 mx-auto my-16">
                    {showBlogPosts && <BlogPosts />}
                    <div class="flex flex-col items-center justify-between space-y-16 my-16">
                        <div class="max-w-7xl px-4 mx-auto font-serif">
                            <H2 text={title} />
                        </div>
                    </div>
                    <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-8 lg:gap-x-32 space-y-8 lg:space-y-0">
                        <div class="flex flex-col items-start justify-start space-y-4">
                        <div class="inline space-x-2 font-serif text-lg transition-colors duration-400">
                                <a class="uppercase">Blurb</a>
                                <svg fill="none" viewBox="0 0 24 24" strokeWidth={0.5} stroke="currentColor" class="inline w-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
                                </svg>
                                <a class="text-center">{blurb}</a>
                            </div>
                            <div class="flex w-full mx-auto border-t" />
                            <div class="inline space-x-2 font-serif text-lg transition-colors duration-400">
                                <a class="uppercase">Project</a>
                                    <svg fill="none" viewBox="0 0 24 24" strokeWidth={0.5} stroke="currentColor" class="inline w-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
                                    </svg>
                                    <a class="text-center">{details}</a>
                            </div>
                        
                            <div class="flex w-full mx-auto border-t" />
                            <div class="inline space-x-2 font-serif text-lg transition-colors duration-400">
                                <a class="uppercase">Contribution</a>
                                <svg fill="none" viewBox="0 0 24 24" strokeWidth={0.5} stroke="currentColor" class="inline w-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
                                </svg>
                                <a class="text-center">{contribution}</a>
                            </div>
                            
                            
                        
                        </div>
                        <div class="flex flex-col items-center justify-start">
                            <img 
                                src={require(`../assets/Projects/${selectedProject.asset}`)} 
                                alt={selectedProject.title}
                                class="object-fill w-full"
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProjectDetails;