import React from 'react';

import parseHTML from '../../functions/blog/parseHtml';

const Markdown = ({ post }) => {

    return (
        <div className="max-w-2xl mx-auto prose prose-lg flex relative flex-col items-center justify-between">
            {post && parseHTML(post.content)}
        </div>
    );
};

export default Markdown;