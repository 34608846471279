import React from 'react';

const H4 = (props) => {
    return (
        <div className="col space-y-8 items-center justify-center">
            <h1 className="font-serif text-2xl sm:text-3xl text-gray-700 text-center">{props.text}</h1>
            <div className="h-[2px] w-1/4 max-w-[48px] bg-orange-400 mx-auto rounded-full"></div>
        </div>
    );
};

export default H4;