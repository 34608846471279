import React from 'react';

const AppearancesLoading = () => {
    const containers = Array.from({ length: 30 }, (_, index) => (
      <div key={index} class="">
          <div class="flex flex-col items-start justify-center space-y-2">
              <div class="flex flex-col items-start justify-center w-full">
                  <div class="animate-pulse flex w-3/4 space-x-4">
                      <div class="space-y-6 flex-1">
                          <div class="w-1/2 h-3 bg-gray-200 rounded-none"></div>
                          <div class="space-y-3">
                              <div class="grid grid-cols-3 gap-4">
                                  <div class="h-3 bg-gray-200 rounded-none col-span-2"></div>
                                  <div class="h-3 bg-gray-200 rounded-none col-span-1"></div>
                              </div>
                              <div class="grid grid-cols-4 gap-4">
                                  <div class="h-3 bg-gray-200 rounded-none col-span-2"></div>
                                  <div class="h-3 bg-gray-200 rounded-none col-span-2"></div>
                              </div>
                              
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    ));
  
    return (
        <div class="max-w-7xl p-4 w-full">
            <div class="grid grid-cols-1 md:grid-cols-3 gap-x-8 gap-y-16">
                  {containers}
            </div>
        </div>
    );
};

export default AppearancesLoading;
