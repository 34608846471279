import React, { useEffect, useRef } from 'react';

import { useMasterContext } from '../contexts/MasterContext';

import BlogPosts from '../components/Home/BlogPosts';
import Hero from '../components/Home/Hero';
import About from '../components/Home/About';
import RecentPosts from '../components/Home/RecentPosts';
import Contact from '../components/Home/Contact';

const Home = () => {
  const { populateBlogPosts, showBlogPosts } = useMasterContext();
  const aboutRef = useRef(null);

  useEffect(() => {
    populateBlogPosts();
  }, []);

  const handleScrollToAbout = () => {
    aboutRef.current.scrollIntoView({ 
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
      easing: 'ease-in-out',
      duration: 1000,
     });
  };

  return (
        <div className="animate-fade space-y-12">
          {showBlogPosts && <BlogPosts />}
          <Hero handleScrollToAbout={handleScrollToAbout} />
          <div ref={aboutRef}>
            <About />
          </div>
          <RecentPosts />
          <Contact />
        </div>
    
  );
};

export default Home;