import React, { useState } from 'react';

const CentralEmergenceUnderline = (props) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <a 
            href={props.href}    
            target={props.target || null}
        >
            <span
                className={`relative inline-block cursor-pointer text-${props.size} text-gray-700 hover:text-gray-500`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {props.text}
                <span
                    className={`absolute inset-x-0 bottom-0 rounded-full h-[1px] bg-gray-500 transition-all ease-out duration-300 transform origin-center ${ isHovered ? 'scale-x-100' : 'scale-x-0' }`}
                ></span>
            </span>
        </a>
    );
};

export default CentralEmergenceUnderline;
