import React, { useState, useEffect, useRef } from 'react';

import { useMasterContext } from '../../contexts/MasterContext';

import H2 from '../UI/H2';

const CTA = () => {

    const { content } = useMasterContext();

    const [subtitle, setSubtitle] = useState('');
    const [paragraph, setParagraph] = useState('');
   
    useEffect(() => {
        if (content) {
            const contact = content.pages.filter((item) => item.title === 'Contact')[0];
            const cta = contact.sections.filter((item) => item.title === 'CTA')[0];
            setSubtitle(cta.subtitle);
            setParagraph(cta.paragraph);
        }
    }, [content]);

    return (
        <div>
            <div class="max-w-7xl px-4 d:px-0 mx-auto font-serif">
                <div class="grid grid-cols-1  space-y-8 lg:space-y-0 lg:space-x-8 items-center justify-items-stretch">
                    <div class="flex flex-col items-center justify-center w-full col-span-5 space-y-16 max-w-5xl mx-auto">
                        <H2 text="Let's work together" />
                        <div class="flex flex-col space-y-4 w-full">
                            <p class="text-lg text-center font-normal text-gray-500">Jami is passionate about justice, equity, and opportunity for all people in our American experiment with democracy. She speaks on a variety of issues around these, and other topics at high school and college campuses, media organizations, and nonprofit events. She has been a Principal for a Day through P.E.N.C.I.L., served on the boards of Gideon's Promise and Inspiring Young Minds, and served as a regional panelist for the White House Fellows Program under Presidents Bill Clinton and George W. Bush. As a television reporter, Jami Floyd has traveled to forty-eight states, covering the struggle for economic empowerment, law and injustice, and the destructive weather systems that are part of climate change. She has interviewed hundreds of newsmakers in the field, and as a TV anchor, she has helped train scores of reporters at the local, cable, and network levels. Her greatest professional fulfillment has come from mentoring young talent in law and journalism. While Jami Floyd appreciates the power of social media, Jami still cherishes genuine human connections. She is happy to speak with your organization about any number of social issues, moderate a panel around a thorny topic, or work with you/your group around ways to better communicate and feel more empowered. Jami would love to work with you!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CTA;
